.empty {
  text-align: center;
  font-style: italic;
  font-size: 14px;
  width: 100%;
}

.incomeDescription {
  font-size: 12px;
  padding-top: 10px;
  padding-bottom: 15px;
}

.mgWarning {
  text-align: center;
  font-size: 12px;
  font-style: italic;
  color: #64dcbe;
}

.incomeTitle {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #64dcbe;
  background-color: #282828;
  line-height: 37px;
  margin: 0;
  padding-right: 30px;
}

.bubblesContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.bubble {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30%;
  max-width: 300px;
  height: 130px;
  border: 2px solid #64dcbe;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 30px;
  padding: 18px;
  box-sizing: border-box;
}

.bubble p {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.bubble h4 {
  color: #64dcbe;
  margin: 0;
  font-size: 50px;
  font-weight: bold;
}

.incomeType {
  width: 100%;
  z-index: 50;
  position: relative;
}

.incomeType p {
  text-align: center;
  margin: 0;
  font-size: 13px;
  color: #64dcbe;
  font-weight: bold;
  margin-bottom: -22px;
  margin-top: 8px;
}
