.formContainer {
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;
}

.formTitle {
  font-size: 18px;
  font-weight: bold;
}

.input {
  margin-right: 10px;
  margin-left: 10px;
}
