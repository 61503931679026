.dashboard {
  margin-left: 10px;
  padding: 30px;
  flex: 1;
}

.dashboard h3 {
  color: #64dcbe;
}

.cardsContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30%;
  max-width: 400px;
  min-height: 180px;
  border: 2px solid #64dcbe;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 30px;
  padding: 25px 10px;
  box-sizing: border-box;
}

.card :first-child {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.card h4 {
  color: #64dcbe;
  margin: 0;
  font-size: 65px;
  font-weight: bold;
}

.reminderTitle {
  color: #64dcbe;
  margin: 0;
  font-size: 20px;
  font-weight: bold;
}

.reminderText {
  margin: 0;
  margin-top: 15px;
}
