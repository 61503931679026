.codeClub {
  color: #64dcbe;
  text-align: right;
  font-weight: bold;
  padding-right: 20px;
}

.usefullLinks {
  position: absolute;
  bottom: 0;
  width: 210px;
  text-align: center;
  font-size: 14px;
  color: #64dcbe;
  padding-bottom: 40px;
}

.usefullLinks a {
  text-decoration: none;
  color: #64dcbe;
}

.usefullLinks a:hover {
  color: white;
}

@media screen and (max-width: 1224px) {
  .usefullLinks {
    width: 60px;
    font-size: 12px;
    line-height: 20px;
  }
}
