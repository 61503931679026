.detailsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;
}

.detailsCard {
  width: 18%;
  margin-left: 20px;
  min-height: 110px;
  border: 2px solid #64dcbe;
  border-radius: 10px;
  text-align: center;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  margin: 5px 0 0;
  height: 40px;
}

.detailsCard h4 {
  color: #64dcbe;
  font-weight: bold;
  font-size: 40px;
  margin: 0;
}

.checkboxContainer {
  display: flex;
  justify-content: flex-start;
  pointer-events: none;
  margin-top: 5px;
}

.checkboxContainer p {
  text-align: left;
  margin: 0;
}

.downloadModal {
  width: 300px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // don't erase margin it allow the modal to grow with content
  margin: 10px;
}

.downloadModal h3 {
  margin: 0;
}

.downloadModal p {
  margin: 0;
  padding: 2px;
  font-size: 12px;
  color: grey;
}

.download {
  border: none;
  background-color: #64dcbe;
  color: white;
  width: 200px;
  height: 40px;
  margin-top: 10px;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  line-height: 40px;
}

.download:hover {
  cursor: pointer;
  background-color: adjust-color($color: #64dcbe, $lightness: -20%);
}

.disabled {
  background-color: grey;
  pointer-events: none;
}
